import React from 'react'
import { Center, useDisclosure } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../Layout'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
// import Link from '../../components/Link'
import BackgroundImage from '../../components/BackgroundImage'
import { Media, responsive } from '../../components/ThemeProvider/theme'

import IslandButton from '../../components/IslandButton'
import SupportAndReport from '../../components/SupportAndReport'
// import RegisterModal from '../../components/RegisterModal'

import exam from './exam.svg'
import radar from './radar.svg'
import star from './star.svg'
import triangle from './triangle.svg'
import votepaper from './paper.svg'
import Banner from '../Banner'
import title from '../HomePage/images/sections/measure.svg'

const Measure = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Layout overflow="hidden" title="心理數質測量站"  bg="custom.lightPinkBg">
      <Banner
        photo={<StaticImage src="./banner-mobile.png" layout="fullWidth" />}
        photoWeb={<StaticImage src="./banner-web.png" layout="fullWidth" />}
        text={"進入測量站\n用另一種方式更了解台灣、也更認識自己"}
        left={responsive('-0.75em', 0)}
        textWidth="49.33%"
        title={<BackgroundImage src={title} ratio={501 / 85} />}
      />
      <Flex
        position="relative"
        alignItems={responsive('flex-end', 'center')}
        justifyContent="space-between"
        pt={responsive('9.625em', '6em')}
      >
        <Box
          width={responsive('40%', '27%')}
          ml={responsive('1.25em', '15%')}
          pt={responsive('0', '1rem')}
          fontSize={responsive('0.875em', '1.125em')}
        >
          <Text.SectionTitle>台灣認知大考驗</Text.SectionTitle>
          <Text>
            花10分鐘，測測看你與台灣的距離有多遠<br />
            獲得專屬認知分析報告與個人化學習推薦！
          </Text>
          <IslandButton
            mt="1rem"
            color="custom.buttonYellow"
            width={responsive('9.25rem', '10.75rem')}
            fontSize={responsive('1em', '1.25em')}
            to="/measure/exam/"
          >
            完整了解測驗
          </IslandButton>
        </Box>
        <Box.Relative width={responsive('55%', '45%')} maxWidth="36em">
          <BackgroundImage src={exam} ratio={199 / 238} />
        </Box.Relative>
        <Box.Absolute width={responsive('32%', '11%')} top={responsive('15.2%', '19.25%')} left={responsive('-1.25em', '-0.75em')}>
          <BackgroundImage src={radar} ratio={144 / 140} />
        </Box.Absolute>
        <Box.Absolute transform={responsive('rotate(-11.74deg)', 'none')} width={responsive('36%', '17%')} top={responsive('21.5%', '5.4%')} left={responsive('15.4%', '28.6%')}>
          <BackgroundImage src={star} ratio={252 / 207} />
        </Box.Absolute>
      </Flex>
      <Flex position="relative" alignItems={responsive('flex-end', 'center')} pt={responsive('9em', '1.875em')}>
        <Box.Relative
          width={responsive('84.3%', '53.1%')}
          maxWidth="36em"
          left={responsive('-32%', '-3.8%')}
          transform={responsive('rotate(6.8deg)', 'rotate(-22.63deg)')}
        >
          <BackgroundImage src={votepaper} ratio={692 / 602} />
          {/* <Box.Absolute
            fontWeight="bold"
            as={Center}
            textAlign={responsive('left', 'center')}
            left={responsive('32%', '0')}
            right="0"
            top="0"
            bottom="0"
            width="80%"
            transform={responsive('none', 'rotate(22.63deg)')}
          >
            <Text fontSize={responsive('0.875em', '1.25em')}>（搶先預約）</Text>
          </Box.Absolute> */}
        </Box.Relative>
        <Box
          ml={responsive('0', '12.9%')}
          width={responsive('40%', '27%')}
          fontSize={responsive('0.875em', '1.125em')}
          position={responsive('absolute', 'static')}
          left={responsive('55%', 'unset')}
          bottom={responsive('0%', 'unset')}
        >
          <Text.SectionTitle>全民來7投 連續投7天<br/>2021公投實驗</Text.SectionTitle>
          <Text textAlign="justify">
            如果公投不只同意不同意，結果會有什麼不一樣？<br />
            與法律白話文運動合作，實驗用公投開啟對話的可能
          </Text>
          <IslandButton
            mt="1rem"
            color="custom.buttonYellow"
            width={responsive('9.25rem', '10.75rem')}
            fontSize={responsive('1em', '1.25em')}
            to="/blog/2021vote/"
          >
            看實驗結果
          </IslandButton>
        </Box>
        <Box.Absolute width={responsive('33%', '11.7%')} top={responsive('12.9%', '85.7%')} right={responsive('28%', '3.5%')}>
          <BackgroundImage src={triangle} ratio={195 / 215} />
        </Box.Absolute>
      </Flex>
      <SupportAndReport />
      {/* <RegisterModal isOpen={isOpen} onClose={onClose} title="搶先預約「2021公投測驗」上線通知" text={`2021公投測驗預計在11月推出！\n留下你的E-mail，島懶員就會優先通知你！`} /> */}
    </Layout>
  )
}

export default Measure
